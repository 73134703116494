import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "@components";
import {
  BusinessSection,
  ClientsSection,
  FaqSection,
  PlatformSection,
  QuizSection,
  SelfworkSection,
  TopSection,
  TrustSection
} from "@templates/main";

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`Сайт Самозанятые РФ ${new Date().getFullYear()} - официальная платформа для самозанятых граждан РФ и работы с ними | Портал и площадка с отзывами`}</title>
      <meta name="description" content="Ищите официальную платформу для работы с самозанятыми гражданами РФ? Наш сайт поможет вам с этим. Официальные выплаты самозанятым. Автоматизация документооборота" />
      <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" sizes="any" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" type="image/png" />
    </Helmet>

    <Layout>

      <TopSection />
      <PlatformSection />
      <BusinessSection />
      <SelfworkSection />
      <ClientsSection />
      <TrustSection />
      <QuizSection />
      <FaqSection />

    </Layout>
  </>
);

export default IndexPage;
